import { Component, OnInit } from '@angular/core';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  tableLoading = false;
  columns = ['datetime','source','title'];
  dataSource:any = [];

  constructor(
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.tableLoading = true;
    this.newsService.getNews().subscribe( (data) => {
      if(data.result) {
        this.dataSource = data.news;
        this.tableLoading = false;
      }
    });
  }

}
