<div class="mb-3">
    <div class="text-center text-primary p-1">
        <span>News</span>
    </div>
    <div class="table-loading-bar">
        <mat-progress-bar mode="indeterminate" *ngIf="tableLoading"></mat-progress-bar>
    </div>
    <table mat-table #invTable [dataSource]="dataSource" class="news-table mat-elevation-z1">
        <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element"> {{element.datetime | date:'short':'it'}} </td>
        </ng-container>

        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef>Fonte</th>
            <td mat-cell *matCellDef="let element"> {{element.source}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Titolo</th>
            <td mat-cell *matCellDef="let element">
                <mat-accordion>
                    <mat-expansion-panel class="plain-expansion-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{element.title}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="news-body text-primary">{{element.body}}</div>
                    </mat-expansion-panel>
                </mat-accordion>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="9999">
                Nessuna notizia trovata
            </td>
        </tr>
    </table>
</div>