<div class="text-center text-primary p-1">
    <span>FTSE MIB</span>
</div>
<div class="text-center">
    <mat-button-toggle-group appearance="legacy" aria-label="line chart view" class="xs-button-group">
        <mat-button-toggle value="bold" (change)="toggleChartView('1M')" [checked]="selectedChartView == '1M'">1M</mat-button-toggle>
        <mat-button-toggle value="italic" (change)="toggleChartView('6M')" [checked]="selectedChartView == '6M'">6M</mat-button-toggle>
        <mat-button-toggle value="underline" (change)="toggleChartView('1Y')" [checked]="selectedChartView == '1Y'">1Y</mat-button-toggle>
    </mat-button-toggle-group>
</div>
<div class="table-loading-bar pt-2">
    <mat-progress-bar mode="indeterminate" *ngIf="isChartLoading"></mat-progress-bar>
</div>
<canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="lineChartLegend" [type]="lineChartType" [plugins]="lineChartPlugins"></canvas>