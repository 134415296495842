import { Component, OnInit } from '@angular/core';
import { TestService } from '../test.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(public testService: TestService) { }

  ngOnInit(): void {
    console.log("init");
    
    this.testService.test().subscribe((data)=>{
      console.log(data);
    })
  }

}
