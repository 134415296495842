import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from 'src/app/services/wallet.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/jwt.service';
import { EventBusService } from '../../../shared/event-bus.service';
import { EventData } from '../../../shared/event.class';
import { Wallet } from 'src/app/models/wallet.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wallet-delete-dialog',
  templateUrl: './wallet-delete-dialog.component.html',
  styleUrls: ['./wallet-delete-dialog.component.scss']
})
export class WalletDeleteDialogComponent implements OnInit {
  userId = "";
  wallet: Wallet;

  constructor(
    private dialogRef: MatDialogRef<WalletDeleteDialogComponent>,
    private walletService: WalletService,
    private toastService: ToastService,
    private router: Router,
    private jwtServie: JwtService,
    private eventBusService: EventBusService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.userId = this.jwtServie.getUser().id;
    this.wallet = data.wallet;
  };

  ngOnInit(): void {}

  deleteWallet(): void {
    this.walletService.delete(this.wallet.name_slug).subscribe( (data) => {
      this.toastService.show(data.message.toString(), {
        classname: (data.result ? 'bg-success' : 'bg-danger') + ' text-light',
        delay: environment.toastDelay,
        autohide: true,
        result: data.result
      });
      if(data.result) {
        this.eventBusService.emit(new EventData('reloadWallets', null));
      }
      this.close();
    })
  }

  close(): void {
    this.dialogRef.close();
  }
}
