<div class="wallet-stats text-primary bg-accent align-items-center m-3">
    <div class="mat-card-title">
        <div id="wallet-name">
            <span>
                    {{wallet?.name}}
                </span>
            <div class="fs-6">
                {{wallet?.unique_id}}
            </div>
        </div>
        <button *ngIf="!wallet?.isGlobal" mat-icon-button color="primary" aria-label="add transaction button" (click)="openDeleteWallet()">
                <i class="fa-regular fa-trash-can icon-regular text-danger"></i>
            </button>
    </div>
    <div class="d-flex align-items-center">
        <div>Tot. Acquisto: <span>{{walletStats.total | number:'1.0-2':'it-IT'}}</span></div>
        <div>Tot. Ctv: <span>{{walletStats.totalCountervalue | number:'1.0-2':'it-IT'}}</span></div>
        <div>Tot. Perd/Prof: <span [ngClass]="walletStats.totalGainLoss >= 0 ? 'text-success' : 'text-danger'">{{walletStats.totalGainLoss | number:'1.0-2':'it-IT'}}</span></div>
        <div>Tot. Var%: <span [ngClass]="walletStats.totalPercChange >= 0 ? 'text-success' : 'text-danger'">{{walletStats.totalPercChange | number:'1.0-2':'it-IT'}}%</span></div>
    </div>
    <div>

    </div>
</div>
<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Vista estesa">
        <div class="px-3">
            <button *ngIf="!wallet?.isGlobal" mat-stroked-button color="primary" class="my-3" [routerLink]="['add']">Aggiungi un titolo</button>
            <button *ngIf="!wallet?.isGlobal" mat-stroked-button class="my-3 ms-2 text-success" (click)="openUploadExcel()">Carica da Excel <i class="fas fa-file-excel"></i></button>
            <div class="table-loading-bar">
                <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            </div>
            <table mat-table #walletTable [dataSource]="wallet?.stocks" class="mat-primary mat-elevation-z1" matSort matSortActive="description" matSortDisableClear matSortDirection="desc" (matSortChange)="loadStocks()">
                <ng-container matColumnDef="description">
                    <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef> Descrizione </th>
                    <td mat-cell *matCellDef="let element"> {{element.stock.Descrizione}} </td>
                </ng-container>
                <ng-container matColumnDef="exchange">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Mercato </th>
                    <td mat-cell *matCellDef="let element"> {{element.stock.MercatoBIT}} </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef> Qta </th>
                    <td mat-cell *matCellDef="let element"> {{element.totQuantity | number:'1.0-4':'it-IT'}} </td>
                </ng-container>
                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef> Divisa </th>
                    <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
                </ng-container>
                <ng-container matColumnDef="exchangeRate">
                    <th mat-header-cell *matHeaderCellDef> Cambio acq </th>
                    <td mat-cell *matCellDef="let element"> {{element.exchangeRate | number:'1.0-4':'it-IT'}} </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Prezzo acq </th>
                    <td mat-cell *matCellDef="let element"> {{element.avgPrice | number:'1.0-4':'it-IT'}} </td>
                </ng-container>
                <ng-container matColumnDef="lastPrice">
                    <th mat-header-cell *matHeaderCellDef> Ult. prezzo </th>
                    <td mat-cell *matCellDef="let element"> {{element.stock.last_price | number:'1.0-4':'it-IT'}} </td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef> Tot. acq </th>
                    <td mat-cell *matCellDef="let element"> {{element.total | number:'1.0-2':'it-IT'}} </td>
                </ng-container>
                <ng-container matColumnDef="counterValue">
                    <th mat-header-cell *matHeaderCellDef> Ctv </th>
                    <td mat-cell *matCellDef="let element"> {{element.countervalue | number:'1.0-2':'it-IT'}} </td>
                </ng-container>
                <ng-container matColumnDef="gainLoss">
                    <th mat-header-cell *matHeaderCellDef> Perd / Prof </th>
                    <td mat-cell *matCellDef="let element"> <span [ngClass]="element.gain_loss >= 0 ? 'text-success' : 'text-danger'">{{element.gain_loss | number:'1.0-2':'it-IT'}}</span> </td>
                </ng-container>
                <ng-container matColumnDef="percChange">
                    <th mat-header-cell *matHeaderCellDef> Var% </th>
                    <td mat-cell *matCellDef="let element"> <span [ngClass]="element.perc_change >= 0 ? 'text-success' : 'text-danger'">{{element.perc_change | number:'1.0-2':'it-IT'}}%</span></td>
                </ng-container>
                <ng-container matColumnDef="tools">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="!wallet?.isGlobal" mat-icon-button color="primary" aria-label="add transaction button" (click)="onAddTransaction(element)">
                                <i class="fa-solid fa-square-plus icon-regular"></i>
                            </button>
                        <button mat-icon-button color="primary" aria-label="transaction history button" (click)="onShowHistory(element)">
                                <i class="fa-solid fa-clock-rotate-left icon-regular"></i>
                            </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" colspan="9999">
                        Nessun titolo nel portafoglio. <button *ngIf="!wallet?.isGlobal" mat-stroked-button color="primary" [routerLink]="['add']" [queryParams]="{walletName: wallet?.name}">Aggiungi un titolo</button>
                    </td>
                </tr>
            </table>
            <mat-paginator class="mt-3" #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"></mat-paginator>
        </div>
    </mat-tab>
    <mat-tab label="Vista sintetica">
        <div class="chart-wrapper">
            <canvas baseChart [data]="compositionData" [type]="'doughnut'" [options]="chartOptions"></canvas>
        </div>
    </mat-tab>
</mat-tab-group>