import { NgModule,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { IndexComponent } from './test/index/index.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar/navbar.component';
import { UserComponent } from './user/user.component';
import { WalletsRoutingModule } from './wallets/wallets-routing.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import {MatButtonModule} from '@angular/material/button';
import { ToastComponent } from './toast/toast.component';
import { SanitizeHtmlPipe } from './utils/sanitize-html-pipe';
import { SafePipeModule } from 'safe-pipe';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { NgChartsModule } from 'ng2-charts';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import localeIT from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';

registerLocaleData(localeIT);

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    LogoutComponent,
    NavbarComponent,
    UserComponent,
    NotFoundComponent,
    ToastComponent,
    SanitizeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    WalletsRoutingModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    MatButtonModule,
    SafePipeModule,
    MatCardModule,
    MatTableModule,
    NgChartsModule,
    MatTabsModule,
    MatProgressBarModule,
    HomeModule,
    MatExpansionModule,
    LoginModule
  ],
  providers: [
    authInterceptorProviders,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: LOCALE_ID, useValue: 'it-IT'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
