<div class="table-loading-bar">
    <mat-progress-bar mode="indeterminate" *ngIf="indexTableLoading"></mat-progress-bar>
</div>
<table mat-table #mainIndexTable [dataSource]="dataSource" class="w-100 mat-elevation-z1">
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Indice</th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element"> <span>{{element.date}} </span></td>
    </ng-container>

    <ng-container matColumnDef="last">
        <th mat-header-cell *matHeaderCellDef>Valore</th>
        <td mat-cell *matCellDef="let element" ngClass="{{element.last >= 0 ? 'text-success' : 'text-danger'}}"> <span>{{element.last | number:'1.0-4':'it-IT'}} </span></td>
    </ng-container>

    <ng-container matColumnDef="percChange">
        <th mat-header-cell *matHeaderCellDef>Var%</th>
        <td mat-cell *matCellDef="let element" ngClass="{{element.percChange >= 0 ? 'text-success' : 'text-danger'}}"> <span>{{element.percChange | number:'1.0-2':'it-IT'}} % </span></td>
    </ng-container>

    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" ngClass="{{element.percChange >= 0 ? 'text-success' : 'text-danger'}}"> <span><i [ngClass]="{'fa-arrow-right':element.percChange == 0, 'fa-arrow-up':element.percChange > 0, 'fa-arrow-down':element.percChange < 0 }" class="fas "></i></span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>