<mat-sidenav-container>
    <mat-sidenav class="sidebar-w-margin sidebar-primary-gradient" mode="side" opened>
        <sidebar></sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="site-content">
            <div id="wrapper-wallets">
                <nav mat-tab-nav-bar mat-stretch-tabs>
                    <a mat-tab-link *ngFor="let wallet of wallets" [active]="selectedWallet?.name_slug == wallet.name_slug" (click)="selectWallet({value: wallet})">
                        {{wallet.name}}
                    </a>
                </nav>
                <button mat-raised-button color="primary" class="create-wallet-btn" (click)="openCreateWallet()">Crea Portafoglio</button>
            </div>
            <div class="col-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>