<h2 mat-dialog-title>Storico transazioni - {{walletStock.stock.Descrizione}}</h2>
<mat-dialog-content>
    <div class="table-loading-bar">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    </div>
    <table mat-table #historyTable [dataSource]="historyData" class="mat-primary mat-elevation-z8 mb-3">
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.type | translate | titlecase}} </td>
        </ng-container>
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Prezzo acq </th>
            <td mat-cell *matCellDef="let element"> {{element.price | number:'1.0-3':'it-IT'}} </td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantita </th>
            <td mat-cell *matCellDef="let element"> {{element.quantity | number:'1.0-3':'it-IT'}} </td>
        </ng-container>
        <ng-container matColumnDef="fee">
            <th mat-header-cell *matHeaderCellDef> Commissioni </th>
            <td mat-cell *matCellDef="let element"> {{element.fixedFee > 0 ? (element.fixedFee | number:'1.0-3':'it-IT') : ((element.percFee | number:'1.0-3':'it-IT')+'%')}} </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> Data </th>
            <td mat-cell *matCellDef="let element"> {{element.created_at | date:'short':'it'}} </td>
        </ng-container>
        <ng-container matColumnDef="tools">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="9999">
                Nessuna transazione.
            </td>
        </tr>
    </table>
</mat-dialog-content>