import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { JwtService } from './services/jwt.service';
import { EventBusService } from './shared/event-bus.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastService } from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.appName;
  eventBusSub?: Subscription;
  env = environment;
  isLoggedIn = false;

  constructor(
    private router: Router,
    private jwtService: JwtService,
    private cdr: ChangeDetectorRef,
    private eventBusService: EventBusService,
    public toastService: ToastService
  ) { }

  ngOnInit(): void {    
    this.eventBusSub = this.eventBusService.on('logout', () => {
      console.log("call app logout");
            
      this.logout();
    });
    this.eventBusSub = this.eventBusService.on('login', () => {
      this.loggedIn();
    });
    this.isLoggedIn = this.jwtService.isLoggedIn();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  logout(): void {
    this.isLoggedIn = false;
    this.jwtService.logout();
    this.router.navigate(['/login']);
  }

  loggedIn(): void {
    this.isLoggedIn = true;
  }

  showSuccess() {
    this.toastService.show('I am a success toast', {
      classname: 'bg-success text-light',
      delay: 2000 ,
      autohide: true,
      headertext: 'Toast Header'
    });
  }
}
