import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { JwtService } from '../services/jwt.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  user: any = {};

  @Input() isLoggedIn = false;

  constructor(
    private jwtService: JwtService
  ) { } 

  ngOnInit(): void {
    this.user = this.jwtService.getUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['isLoggedIn']) {
      
    }
  }
}
