import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WalletService } from 'src/app/services/wallet.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wallet-create',
  templateUrl: './wallet-create.component.html',
  styleUrls: ['./wallet-create.component.scss']
})
export class WalletCreateComponent implements OnInit {
  createForm = new FormGroup({
    name: new FormControl('', Validators.required),
    unique_id: new FormControl('', Validators.required),
    currency: new FormControl('', Validators.required)
  });
  currencies:any = [];

  constructor(
    private walletService: WalletService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.loadCurrencies();
  }

  loadCurrencies(): void {
    this.walletService.currencies().subscribe((data)=>{
      this.currencies = data.currencies;            
    });
  }

  onSubmit(): void {
    if(this.createForm.valid) {
      this.walletService.create(
        this.createForm.controls['name'].value,
        "",
        this.createForm.controls['currency'].value).subscribe((data)=>{
          this.toastService.show(data.message.toString(), {
            classname: (data.result ? 'bg-success' : 'bg-danger') + ' text-light',
            delay: environment.toastDelay,
            autohide: true
          });
        });
    }
  }
}
