import { Component, OnInit } from '@angular/core';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'macro-economic-calendar',
  templateUrl: './macro-economic-calendar.component.html',
  styleUrls: ['./macro-economic-calendar.component.scss']
})
export class MacroEconomicCalendarComponent implements OnInit {
  dataSource: any = [];
  tableLoading = false;
  displayedColumns = ['event','date','period','actual','previous','adjusted_previous','market_consensus','country'];

  constructor(
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.getEvents();
  }

  getEvents() :void {
    this.tableLoading = true;
    this.newsService.getMacroEconomicCalendarEvents().subscribe( (data) => {      
      this.tableLoading = false;
      if(data.result) {
        this.dataSource = data.data;
      }
    });
  }

}
