<mat-card-content class="mt-3">
    <div class="mt-2">
        <a class="navbar-brand" routerLink="/">
            <img src="../assets/logo.gif" width="150" alt="">
        </a>
    </div>
    <div id="avatar" class="mx-auto my-3">
        <img src="../assets/avatar_placeholder.png" width="100" alt="">
    </div>
    <div class="username pt-2 fs-4 align-self-center text-center">Luca Buratti</div>
    <div class="user-title align-self-center text-center">Senior Vice President</div>
    <hr>
    <div class="sidenav-actions d-flex flex-column">
        <ng-template [ngIf]="isLoggedIn">
            <button mat-button color="accent" routerLink="/" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact: true}">Home</button>
            <button mat-button color="accent" [routerLink]="['/user', user.id, 'wallets']" routerLinkActive="link-active">Portafoglio</button>
            <button mat-button color="accent" routerLink="/logout" routerLinkActive="link-active">Logout</button>
        </ng-template>
        <li *ngIf="!isLoggedIn" class="nav-item">
            <button mat-button color="accent" routerLink="/login">Login</button>
        </li>
    </div>
</mat-card-content>