import { Component, ElementRef, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() buttonLabel = "Seleziona il file Excel";
  @Input() buttonIcon:string|undefined;
  @Output() onFileSelectedEvent = new EventEmitter<string>();

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef | undefined;
  fileSelected:any;

  constructor() { }

  ngOnInit(): void {
  }

  openFileSelector(): void {
    this.fileUpload?.nativeElement.dispatchEvent(new MouseEvent('click'));
  } 

  onFileSelected(event:any) {
    const files = event.target.files;
    this.fileSelected = files[0];
    this.onFileSelectedEvent.emit(this.fileSelected);  
  }

}
