<div class="table-loading-bar">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>
<table mat-table #walletTable [dataSource]="dataSource" class="w-100 mat-elevation-z1">
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>
    <ng-container matColumnDef="percChange">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" ngClass="{{element.percChange >= 0 ? 'text-success' : 'text-danger'}}"> <span>{{element.percChange | number:'1.0-2':'it-IT'}} % </span></td>
    </ng-container>

    <!--tr mat-header-row *matHeaderRowDef="displayedColumns"></tr-->
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>