import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '../not-found/not-found.component';
import { WalletAddComponent } from './wallet-add/wallet-add.component';
import { WalletCreateComponent } from './wallet-create/wallet-create.component';

import { WalletIndexComponent } from './wallet-index/wallet-index.component';
import { WalletShowComponent } from './wallet-show/wallet-show.component';

const walletsRoutes: Routes = [
    {
        path: 'wallets/create',
        component: WalletCreateComponent,
    },
    /*{
        path: 'wallets/:walletName/add', 
        component: WalletAddComponent
    },*/
    {
        path: 'wallets',
        component: WalletIndexComponent,
        children: [
            {
                path: 'global-view',
                component: WalletShowComponent,
            },
            {
                path: ':walletName',
                component: WalletShowComponent,
            },
            {
                path: ':walletName/add', 
                component: WalletAddComponent
            }
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(walletsRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class WalletsRoutingModule { }