import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from 'src/app/services/wallet.service';
import { ToastService } from 'src/app/services/toast.service';
import { Wallet } from 'src/app/models/wallet.model';

@Component({
  selector: 'wallet-transaction-dialog',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {
  stock:any = {};
  wallet: any;
  forceType: boolean = false;
  currencies:any = [];

  form = new FormGroup({
    type: new FormControl('', Validators.required),
    quantity: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    fee: new FormControl(0, Validators.required),
    feeType: new FormControl('fixed', Validators.required),
    //currency: new FormControl('', Validators.required)
  });

  constructor(
    private dialogRef: MatDialogRef<TransactionComponent>,
    private walletService: WalletService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.stock = data.stock;    
    this.form.patchValue({'price':this.stock.last_price});
    this.wallet = data.wallet;
    this.forceType = data.forceType;
    if(this.forceType) {
      this.form.patchValue({
        type: this.forceType
      })
    }
  };

  ngOnInit(): void {
    //this.loadCurrencies();
  }

  onSubmit(): void {
    if(this.form.valid) {
      this.stock.formData = this.form.value;
      this.walletService.add(this.wallet.name_slug, this.stock).subscribe( (data) => {
        if(data.result) {
          this.toastService.show(data.message, {
            classname: 'bg-success text-light',
            delay: 5000 ,
            autohide: true
          });
          this.close(true);
        } else {
          this.toastService.show(data.message, {
            classname: 'bg-danger text-light',
            delay: 5000 ,
            autohide: true
          });
        }
      });
    }
  }

  /*loadCurrencies(): void {
    this.walletService.currencies().subscribe((data)=>{
      this.currencies = data.currencies;            
    });
  }*/

  public cancel() {
    this.close(false);
  }
  public close(value:any) {
      this.dialogRef.close(value);
    }
public confirm() {
    this.close(true);
  }
@HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }

}
