import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { HomeComponent } from './home.component';
import { RankingsModule } from '../rankings/rankings.module';
import { MacroEconomicCalendarModule } from '../macro-economic-calendar/macro-economic-calendar.module';
import { MatTabsModule } from '@angular/material/tabs';
import { LineChartModule } from '../charts/line-chart/line-chart.module';
import { MainIndexesModule } from '../main-indexes/main-indexes.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidebarModule } from '../sidebar/sidebar.module';
import { BestAndWorstModule } from '../best-and-worst/best-and-worst.module';
import { NewsModule } from '../news/news.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    MatButtonModule,
    RouterModule,
    CommonModule,
    NgChartsModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatTableModule,
    RankingsModule,
    MacroEconomicCalendarModule,
    MatTabsModule,
    LineChartModule,
    MainIndexesModule,
    MatSidenavModule,
    SidebarModule,
    BestAndWorstModule,
    NewsModule
  ],
  exports: [
    HomeComponent,
  ]
})
export class HomeModule { }