import * as urlSlug from 'url-slug'

export class Wallet {
    id: string;
    name: string;
    name_slug: string;
    unique_id: string;
    stocks?: any;
    created_at: any;
    updated_at: any;
    currency: any;
    isGlobal: boolean;

    constructor(id: any, name_slug: string, name: string, unique_id: any, created_at:any, updated_at: any, currency: string, stocks?: any, isGlobal?: boolean) {
        this.id = id;
        this.name = name;
        this.name_slug = name_slug;
        this.unique_id = unique_id;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.currency = currency;
        this.stocks = stocks;
        this.isGlobal = isGlobal || false;
    }
}
