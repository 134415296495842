import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LineChartComponent } from './line-chart.component';
import { NgChartsModule } from 'ng2-charts';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    LineChartComponent,
  ],
  imports: [
    MatButtonModule,
    RouterModule,
    CommonModule,
    NgChartsModule,
    MatButtonToggleModule,
    MatProgressBarModule
  ],
  exports: [
    LineChartComponent,
  ]
})
export class LineChartModule { }