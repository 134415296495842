import {Component, TemplateRef} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {ToastService} from '../services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToastComponent {
  constructor(
    public toastService: ToastService,
    private domSanitizer: DomSanitizer
  ) {}

  isTemplate(toast:any) { return toast.textOrTpl instanceof TemplateRef; }
}
