<!--h2 mat-dialog-title>Carica portafoglio da Excel</h2-->
<mat-dialog-content>
    <div *ngIf="!isUploading" class="mt-3">
        <p class="fs-3 mb-3 text-primary">Come caricare il tuo portafoglio tramite file Excel:</p>
        <p class="fs-5 my-4">1. <a class="text-success" href="../assets/template_portafoglio.xlsx" mat-stroked-button download><i class="fas fa-file-excel me-2"></i>Scarica il template Excel</a></p>
        <p class="fs-5 my-4">2. Modifica il template scaricato inserendo le informazioni richieste.</p>
        <div class="alert alert-warning mx-3" role="alert">
            Per ogni titolo, è obbligatorio inserire il <b>Mic Code</b> e <b>Isin</b> o <b>Ticker</b>
        </div>
        <p class="fs-5 mt-4">3.
            <file-uploader [buttonLabel]="'Carica il file modificato'" [buttonIcon]="'fa-upload'" (onFileSelectedEvent)="onFileSelected($event)"></file-uploader>
        </p>
        <div *ngIf="enableUploadButton">
            <button mat-raised-button color="primary" (click)="uploadFile()">Carica il portafoglio</button>
        </div>
    </div>
    <div *ngIf="isUploading" class="mx-auto">
        <p class="fs-3 mb-3 text-primary">Caricamento in corso</p>
        <mat-spinner diameter="40"></mat-spinner>
    </div>
</mat-dialog-content>