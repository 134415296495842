import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from 'src/app/services/wallet.service';
import { ToastService } from 'src/app/services/toast.service';
import { Wallet } from 'src/app/models/wallet.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/jwt.service';
import { EventBusService } from '../../../shared/event-bus.service';
import { EventData } from '../../../shared/event.class';

@Component({
  selector: 'wallet-create-dialog',
  templateUrl: './wallet-create-dialog.component.html',
  styleUrls: ['./wallet-create-dialog.component.scss']
})
export class WalletCreateDialogComponent implements OnInit {
  createForm = new FormGroup({
    name: new FormControl('', Validators.required),
    unique_id: new FormControl('', Validators.required),
    currency: new FormControl('', Validators.required)
  });
  userId = "";
  currencies: any = [];

  constructor(
    private dialogRef: MatDialogRef<WalletCreateDialogComponent>,
    private walletService: WalletService,
    private toastService: ToastService,
    private router: Router,
    private jwtServie: JwtService,
    private eventBusService: EventBusService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.userId = this.jwtServie.getUser().id;
  };

  ngOnInit(): void {
    this.loadCurrencies();
  }

  loadCurrencies(): void {
    this.walletService.currencies().subscribe((data) => {
      this.currencies = data.currencies;
    });
  }

  onSubmit(): void {
    if (this.createForm.valid) { 
      this.walletService.create(
        this.createForm.controls['name'].value,
        this.createForm.controls['unique_id'].value,
        this.createForm.controls['currency'].value).subscribe((data) => {
          this.toastService.show(data.message.toString(), {
            classname: (data.result ? 'bg-success' : 'bg-danger') + ' text-light',
            delay: environment.toastDelay,
            autohide: true,
            result: data.result
          });
          if (data.result && data.nameSlug) {
            this.dialogRef.close();
            this.eventBusService.emit(new EventData('reloadWallets', null));
            this.router.navigate(['user', this.userId, 'wallets', data.nameSlug]);
          }
        });
    }
  }

}
