<nav class="navbar navbar-expand-lg navbar-light primary-gradient">
    <div class="container-fluid">
        <div class="navbar-left">
            <a class="navbar-brand" routerLink="/">
                <img src="../assets/logo.gif" width="150" alt="">
            </a>
        </div>
        <button class="navbar-toggler btn btn-outline-primary" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="navbarTogglerDemo02">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed">
            <ng-template [ngIf]="isLoggedIn">
                <button mat-stroked-button color="accent" routerLink="/" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact: true}">Home</button>
                <button mat-stroked-button color="accent" [routerLink]="['user', user.id, 'wallets']" routerLinkActive="link-active">Portafoglio</button>
                <button mat-stroked-button color="accent" routerLink="/logout" routerLinkActive="link-active">Logout</button>
            </ng-template>
            <!--ng-template [ngIf]="!isLoggedIn">
                <button mat-stroked-button color="accent" routerLink="/login">Login</button>
            </ng-template-->
            <!--ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <ng-template [ngIf]="isLoggedIn">
                    <li class="nav-item active">
                        <a class="nav-link" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['user', user.id, 'wallets']">Portafoglio</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/logout">Logout</a>
                    </li>
                </ng-template>
                <li *ngIf="!isLoggedIn" class="nav-item">
                    <a class="nav-link" routerLink="/login">Login</a>
                </li>
            </ul-->
        </div>
    </div>
</nav>