import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MainIndexesComponent } from './main-indexes.component';

@NgModule({
  declarations: [
    MainIndexesComponent
  ],
  imports: [
    MatButtonModule,
    RouterModule,
    CommonModule,
    NgChartsModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatTableModule
  ],
  exports: [
    MainIndexesComponent
  ]
})
export class MainIndexesModule { }