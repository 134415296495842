import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { JwtService } from '../services/jwt.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private jwtService: JwtService, private router: Router, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.jwtService.getToken();
        if (token != null) {
            //authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
            authReq = this.addTokenHeader(req, token);
        }
        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMsg = '';
                if (error.error instanceof ErrorEvent) {
                    errorMsg = `Error: ${error.error.message}`;
                } else {
                    switch (error.error.status) {
                        case 0: //expired
                            console.log("token expired!!!!! do refresh");
                            return this.handleRefresh(authReq, next);

                            break;
                        case -1: //not valid
                        case -2: //not found
                            this.authService.logout();
                            break;

                        default:
                            break;
                    }

                    errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                }
                return throwError(() => new Error(errorMsg));
            })
        )
    }

    private handleRefresh(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    console.log("refresh data", token);
                    
                    this.jwtService.saveToken(token.access_token);
                    this.refreshTokenSubject.next(token.access_token);

                    return next.handle(this.addTokenHeader(request, token.access_token));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this.jwtService.logout();
                    return throwError(() => new Error("Errore sessione"));
                })
            );
            
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];