import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartType, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-adapter-moment';
import * as moment from 'moment';
import { StockService } from 'src/app/services/stock.service';

@Component({
  selector: 'line-chart[stockKey]',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() stockKey!: string;
  selectedChartView = "1Y";
  isChartLoading = true;
  lineChartData:any[] = [
    { 
      data: [], 
      label: '', 
      borderColor: '#123c6d', 
      backgroundColor: '#40526787',
      pointBackgroundColor: '#123c6d',
      pointBorderColor: '#123c6d',
      fill: 'origin'
    },
  ];
  lineChartLabels: any[] = [];
  lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    scales: {
      xAxis: {
        type: 'time',
        ticks: {
          minRotation: 30
        }
      }
    }
  }
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType: ChartType = 'line';

  constructor(
    private stockService: StockService
  ) {
    moment.locale('it');
  }

  ngOnInit(): void {
    this.getChartData(this.selectedChartView);
  }

  getChartData(chartView: string): void {
    this.isChartLoading = true;
    this.stockService.getLineOnCloseData(this.stockKey, chartView).subscribe( (data) => {
      if(data.result) {
        this.lineChartData[0].data = data.data;      
        this.lineChartLabels = data.labels;
        this.isChartLoading = false;
        //this.chart?.update();
      }
    })
  }

  toggleChartView(type: string): void {
    this.getChartData(type);
  }

}
