<div class="p-2">
    <div class="text-center text-primary p-1">
        <span>INV indicator</span>
    </div>
    <div class="table-loading-bar">
        <mat-progress-bar mode="indeterminate" *ngIf="tableInvLoading"></mat-progress-bar>
    </div>
    <table mat-table #invTable [dataSource]="dataSourceInv" class="mx-auto mat-elevation-z1">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Ddescrizione</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="dpv_value">
            <th mat-header-cell *matHeaderCellDef>DPV</th>
            <td mat-cell *matCellDef="let element">{{element.dpv_value | number:'1.0-4':'it-IT'}} </td>
        </ng-container>

        <ng-container matColumnDef="dpv_perc">
            <th mat-header-cell *matHeaderCellDef>DPV %</th>
            <td mat-cell *matCellDef="let element"><span [ngClass]="element.dpv_perc >= 0 ? 'text-success' : 'text-danger'">{{element.dpv_perc | number:'1.0-4':'it-IT'}}</span></td>
        </ng-container>

        <ng-container matColumnDef="sent_value">
            <th mat-header-cell *matHeaderCellDef>SENT</th>
            <td mat-cell *matCellDef="let element"> {{element.sent_value | number:'1.0-4':'it-IT'}} </td>
        </ng-container>

        <ng-container matColumnDef="sent_perc">
            <th mat-header-cell *matHeaderCellDef>SENT %</th>
            <td mat-cell *matCellDef="let element"><span [ngClass]="element.sent_perc >= 0 ? 'text-success' : 'text-danger'">{{element.sent_perc | number:'1.0-4':'it-IT'}}</span></td>
        </ng-container>

        <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef>Ult. prz</th>
            <td mat-cell *matCellDef="let element">{{element.last | number:'1.0-4':'it-IT'}}</td>
        </ng-container>

        <ng-container matColumnDef="perc_change">
            <th mat-header-cell *matHeaderCellDef>Var %</th>
            <td mat-cell *matCellDef="let element"><span [ngClass]="element.perc_change >= 0 ? 'text-success' : 'text-danger'">{{element.perc_change | number:'1.0-4':'it-IT'}}</span></td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element"> {{element.date | date:'shortDate':'it'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="invColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: invColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="9999">
                Nessun elemento trovato
            </td>
        </tr>
    </table>
</div>

<div class="p-2">
    <div class="text-center text-primary p-1">
        <span>ESG indicator</span>
    </div>
    <div class="table-loading-bar">
        <mat-progress-bar mode="indeterminate" *ngIf="tableEsgLoading"></mat-progress-bar>
    </div>
    <table mat-table #esgTable [dataSource]="dataSourceEsg" class="mx-auto mat-elevation-z1">
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Descrizione</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="global">
            <th mat-header-cell *matHeaderCellDef>Global</th>
            <td mat-cell *matCellDef="let element">{{element.global}} </td>
        </ng-container>

        <ng-container matColumnDef="internal">
            <th mat-header-cell *matHeaderCellDef>Internal</th>
            <td mat-cell *matCellDef="let element">{{element.internal}}</td>
        </ng-container>

        <ng-container matColumnDef="external">
            <th mat-header-cell *matHeaderCellDef>External</th>
            <td mat-cell *matCellDef="let element"> {{element.external}} </td>
        </ng-container>

        <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef>Ult. prz</th>
            <td mat-cell *matCellDef="let element">{{element.last | number:'1.0-4':'it-IT'}}</td>
        </ng-container>

        <ng-container matColumnDef="perc_change">
            <th mat-header-cell *matHeaderCellDef>Var %</th>
            <td mat-cell *matCellDef="let element"><span [ngClass]="element.perc_change >= 0 ? 'text-success' : 'text-danger'">{{element.perc_change | number:'1.0-4':'it-IT'}}</span></td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element"> {{element.date | date:'shortDate':'it'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="esgColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: esgColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="9999">
                Nessun elemento trovato
            </td>
        </tr>
    </table>
</div>