<div class="row justify-content-center">
    <div class="col-12">
        <mat-card>
            <mat-card-title>Aggiungi titoli a <button mat-stroked-button color="primary" [routerLink]="['/user',userId,'wallets',wallet?.name_slug]">{{wallet?.name}}</button></mat-card-title>
            <mat-card-content>
                <form id="addToWalletForm" [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3">
                    <mat-form-field class="mb-3" appearance="outline">
                        <mat-label>Mercato</mat-label>
                        <mat-select formControlName="exchange">
                            <mat-option>
                                -- Seleziona il mercato --
                            </mat-option>
                            <mat-option *ngFor="let exchange of exchanges" [value]="exchange.value">
                                {{exchange.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls['exchange'].hasError('required')">Campo obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field class="mb-3 mx-3" appearance="outline">
                        <mat-label>Descrizione</mat-label>
                        <input matInput formControlName="name" placeholder="Titolo">
                        <mat-error *ngIf="form.controls['name'].hasError('required')">Campo obbligatorio</mat-error>
                    </mat-form-field>
                    <button mat-fab color="primary" aria-label="Cerca">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
                <hr>
                <div class="position-relative">
                    <div class="loading-shade" *ngIf="isLoadingResults">
                        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                    </div>
                    <table mat-table [dataSource]="stocks" class="w-100 mat-elevation-z8 mat-primary">
                        <ng-container matColumnDef="Descrizione">
                            <th mat-header-cell *matHeaderCellDef> Descrizione </th>
                            <td mat-cell *matCellDef="let element"> {{element.Descrizione}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Isin">
                            <th mat-header-cell *matHeaderCellDef> Isin </th>
                            <td mat-cell *matCellDef="let element"> {{element.Isin}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Simbolo">
                            <th mat-header-cell *matHeaderCellDef> Simbolo </th>
                            <td mat-cell *matCellDef="let element"> {{element.Simbolo}} </td>
                        </ng-container>
                        <ng-container matColumnDef="tools">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell class="text-end" *matCellDef="let element">
                                <button mat-stroked-button color="success" (click)="onAdd(element)">Aggiungi</button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center" colspan="9999">
                                Nessun risultato trovato.<br>Modifica i criteri di ricerca.
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>