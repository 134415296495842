<div class="text-center text-primary p-1">
    <span>Calendario Macro Economico</span>
</div>
<div class="table-loading-bar">
    <mat-progress-bar mode="indeterminate" *ngIf="tableLoading"></mat-progress-bar>
</div>
<table mat-table #macroEconomicTable [dataSource]="dataSource" class="mx-auto mat-elevation-z1">
    <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef>Evento</th>
        <td mat-cell *matCellDef="let element"> {{element.event}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element"> <span class="">{{element.date | date:'shortDate':'it'}} </span></td>
    </ng-container>

    <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef>Periodo</th>
        <td mat-cell *matCellDef="let element">{{element.period}}</td>
    </ng-container>

    <ng-container matColumnDef="actual">
        <th mat-header-cell *matHeaderCellDef>Attuale</th>
        <td mat-cell *matCellDef="let element">{{element.actual}}</td>
    </ng-container>

    <ng-container matColumnDef="previous">
        <th mat-header-cell *matHeaderCellDef>Precedente</th>
        <td mat-cell *matCellDef="let element">{{element.previous}}</td>
    </ng-container>

    <ng-container matColumnDef="adjusted_previous">
        <th mat-header-cell *matHeaderCellDef>Precedente Ret.</th>
        <td mat-cell *matCellDef="let element">{{element.adjusted_previous}}</td>
    </ng-container>

    <ng-container matColumnDef="market_consensus">
        <th mat-header-cell *matHeaderCellDef>Market Consensus</th>
        <td mat-cell *matCellDef="let element">{{element.market_consensus}}</td>
    </ng-container>

    <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef>Paese</th>
        <td mat-cell *matCellDef="let element">{{element.country}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="9999">
            Nessun evento trovato
        </td>
    </tr>
</table>