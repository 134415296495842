import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './test/index/index.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { BasicAuthGuard } from './basic-auth.guard';
import { GuestGuard } from './guest.guard';
import { LogoutComponent } from './logout/logout.component';
import { UserComponent } from './user/user.component';
import { UserGuard } from './user.guard';
import { WalletGuard } from './wallet.guard';

const routes: Routes = [
  { 
    path: '', 
    component: HomeComponent, 
    canActivate: [BasicAuthGuard] 
  },
  { 
    path: 'login', 
    component: LoginComponent, 
    canActivate: [GuestGuard]
  },
  { 
    path: 'logout', 
    component: LogoutComponent, 
    canActivate: [BasicAuthGuard]
  },
  { 
    path: 'user/:id', 
    //component: UserComponent,
    canActivate: [BasicAuthGuard, UserGuard],
    loadChildren: () => import('./wallets/wallets.module').then(m => m.WalletsModule),
  },
  { 
    path: '**', 
    component: NotFoundComponent 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
