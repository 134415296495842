import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WalletService } from 'src/app/services/wallet.service';
import { TransactionComponent } from '../dialogs/transaction/transaction.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { HistoryComponent } from '../dialogs/history/history.component';
import { Wallet } from 'src/app/models/wallet.model';
import { WalletDeleteDialogComponent } from '../dialogs/delete/wallet-delete-dialog.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { WalletUploadDialogComponent } from '../dialogs/upload-excel/wallet-upload-dialog.component';

@Component({
  selector: 'app-wallet-show',
  templateUrl: './wallet-show.component.html',
  styleUrls: ['./wallet-show.component.scss']
})
export class WalletShowComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  walletStats:any = {};
  
  compositionData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
  };
  chartOptions: ChartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Composizione portafoglio',
      },
    },
  };
  userId = "";
  walletName = "";
  wallet: Wallet | undefined;
  isGlobalWallet = false;
  columnsAttributes:any = {
    'description': {
      'name': 'Descrizione', 
      'fromRelationship': true
    },
    'exchange': {
      'name': 'MercatoBIT', 
      'fromRelationship': true
    },
    'quantity': {},
    'currency': {},
    'exchangeRate': {},
    'price': {},
    'lastPrice': {},
    'total': {},
    'counterValue': {},
    'gainLoss': {},
    'percChange': {},
    'tools': {},
  };
  
  displayedColumns: string[] = Object.keys(this.columnsAttributes);
  dataSource = new MatTableDataSource();
  @ViewChild(MatTable) walletTable!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private walletService: WalletService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.userId = this.route.parent!.snapshot.paramMap.get('id')!;
    this.route.paramMap.subscribe((paramMap) => {
      if(this.walletName = paramMap.get('walletName')!) {
        this.isGlobalWallet = false;
        this.loadWallet();
      }
    })
    if(!this.walletName) {
      //portafoglio globale
      this.walletName = "global-view";
      this.isGlobalWallet = true;
      this.loadWallet();
    }
  }

  loadWallet(): void {
    this.walletService.get(this.userId, this.walletName, this.isGlobalWallet).subscribe((data) => {
      this.wallet = new Wallet(data.wallet.id, data.wallet.name_slug, data.wallet.name, data.wallet.unique_id, data.wallet.created_at, data.wallet.updated_at, data.wallet.currency, undefined, data.wallet.isGlobal);
      this.loadStocks();
      this.loadChartData(data.composition);
      this.walletStats = data.stats;
    })
  }

  loadChartData(composition:any): void {
    this.compositionData.labels = Object.keys(composition)
    this.compositionData.datasets[0].data = Object.values(composition)
    this.chart?.update();
  }

  loadStocks(): void {
    this.isLoading = true;
    const tableData = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sort: {
        column: this.columnsAttributes[this.sort.active].name,
        fromRelationship: this.columnsAttributes[this.sort.active].fromRelationship,
        direction: this.sort.direction
      }
    }
    this.walletService.getStocks(this.userId, this.wallet!.name_slug, this.isGlobalWallet, tableData).subscribe((data) => {
      this.wallet!.stocks = data.stocks;      
      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = data.totalCount;
      });
      this.isLoading = false;
    })
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadStocks();
  }

  onAddTransaction(stock: any): void {
    this.openAddTransactionDialog(stock);
  }

  onShowHistory(stock: any): void {
    this.openHistoryDialog(stock);
  }

  openAddTransactionDialog(element: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      stock: element.stock,
      wallet: this.wallet,
      hideType: false
    };
    let dialogRef = this.dialog.open(TransactionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.loadWallet();
      }      
    })
  }

  openHistoryDialog(element: any) {    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      walletStock: element,
      wallet: this.wallet,
      userId: this.userId,
    };
    dialogConfig.width = "100%";
    let dialogRef = this.dialog.open(HistoryComponent, dialogConfig);
    /*dialogRef.afterClosed().subscribe(result => {      
      this.loadWallet();
    })*/
  }

  openDeleteWallet(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      wallet: this.wallet
    };
    let dialogRef = this.dialog.open(WalletDeleteDialogComponent, dialogConfig);
  }

  openUploadExcel(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      wallet: this.wallet
    };
    let dialogRef = this.dialog.open(WalletUploadDialogComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.loadWallet();
      }      
    })
  }

}
