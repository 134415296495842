import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import { WalletService } from 'src/app/services/wallet.service';
import { ToastService } from 'src/app/services/toast.service';
import * as urlSlug from 'url-slug'
import { ActivatedRoute } from '@angular/router';
import { Wallet } from 'src/app/models/wallet.model';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'wallet-history-dialog',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  walletStock:any = {};
  wallet: Wallet;
  userId = "";
  forceType: boolean = false;
  currencies:any = [];
  displayedColumns: string[] = ['type','price','quantity','fee','created_at','tools'];
  historyData = new MatTableDataSource();
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<HistoryComponent>,
    private walletService: WalletService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {    
    this.walletStock = data.walletStock; 
    this.wallet = data.wallet;
    this.userId = data.userId;
  };

  ngOnInit(): void {
    this.loadTransactions();
  }

  loadTransactions(): void {   
    this.isLoading = true;     
    this.walletService.getTransactions(this.userId, this.wallet.name_slug, this.walletStock.id, this.wallet.isGlobal).subscribe((data)=>{
      this.historyData = data.transactions;
      this.isLoading = false;
    })
  }

  public cancel() {
    this.close(false);
  }
  public close(value:any) {
      this.dialogRef.close(value);
    }
  public confirm() {
    this.close(true);
  }

  @HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }

}
