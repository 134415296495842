<div class="login-box">
    <div *ngIf="!showSignupDone" class="login-box-inner mat-elevation-z6">
        <div class="text-center m-3">
            <img src="../assets/logo.gif" width="150" alt="">
        </div>
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Accedi">
                <div class="login-form">
                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" name="email" [(ngModel)]="form.email" required #email="ngModel">
                            <label for="floatingInput">Email</label>
                            <div class="alert alert-danger" role="alert" *ngIf="email.errors && f.submitted">
                                Campo obbligatorio
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input type="password" class="form-control" id="floatingPassword" placeholder="Password" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel">
                            <label for="floatingPassword">Password</label>
                            <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
                                <div *ngIf="password.errors['required']">Campo obbligatorio</div>
                                <div *ngIf="password.errors['minlength']">
                                    La password deve essere lunga almeno 6 caratteri
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isLoginFailed" class="alert alert-danger" role="alert">
                            Username o Password errati
                        </div>
                        <div class="d-grid">
                            <button mat-raised-button color="primary">Accedi</button>
                        </div>
                    </form>
                </div>
            </mat-tab>
            <mat-tab label="Registrati">
                <div class="signup-form">
                    <form id="createWalletForm" autocomplete="off" [formGroup]="signupForm" (ngSubmit)="onSignup()" class="mt-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="first_name" placeholder="Nome">
                            <mat-error *ngIf="signupForm.controls['first_name'].hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Cognome</mat-label>
                            <input matInput formControlName="last_name" placeholder="Cognome">
                            <mat-error *ngIf="signupForm.controls['last_name'].hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" formControlName="email" placeholder="Email">
                            <mat-error *ngIf="signupForm.controls['email'].hasError('required')">Campo obbligatorio</mat-error>
                            <mat-error *ngIf="signupForm.controls['email'].hasError('email')">Email non valida</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" formControlName="password" placeholder="Password">
                            <mat-error *ngIf="signupForm.controls['password'].hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Località</mat-label>
                            <input matInput formControlName="location" placeholder="Località">
                            <mat-error *ngIf="signupForm.controls['location'].hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Tipologia utente</mat-label>
                            <mat-select formControlName="user_type" (selectionChange)="onUserTypeChange($event)">
                                <mat-option value="private">
                                    Investitore privato
                                </mat-option>
                                <mat-option value="ifa">
                                    IFA - Consulente finanziario
                                </mat-option>
                                <mat-option value="fund">
                                    Gestore fund manager
                                </mat-option>
                                <mat-option value="institutional">
                                    Istituzionale
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="signupForm.controls['user_type'].hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="signupForm.controls['user_type'].value == 'fund'" appearance="outline">
                            <mat-label>Nome società</mat-label>
                            <input matInput formControlName="institution_name" placeholder="Società">
                            <mat-error *ngIf="signupForm.controls['institution_name'].hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <button mat-raised-button class="w-100" color="primary">Registrati</button>

                    </form>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div *ngIf="showSignupDone" class="signup-done-inner mat-elevation-z6">
        <div>
            <i class="far fa-check-circle text-success"></i>
        </div>
        <h1 class="text-primary mt-3">Registrazione completata</h1>
        <hr>
        <h2 class="text-primary">Riceverai una email contenente il link per confermare la registrazione</h2>
    </div>
</div>