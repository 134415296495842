import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RankingsService {

  constructor(private httpClient: HttpClient) { }

  getInvIndicators(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'inv-indicators');
  }

  getEsgIndicators(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'esg-indicators');
  }
}
