import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private httpClient: HttpClient) { }

  getAll(userId: number|string): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'user/'+userId+'/wallets', {});
  }

  get(userId: number|string, walletNameSlug: string, isGlobalWallet: boolean = false): Observable<any> {
    let url = "";
    if(!isGlobalWallet) {
      url = '/wallet/'+walletNameSlug;
    } else {
      url = '/wallet-global/';
    }
    return this.httpClient.post(environment.apiUrl+'user/'+userId+url, {});
  }

  getStocks(userId: number|string, walletNameSlug: string, isGlobalWallet: boolean = false, tableData: any): Observable<any> {
    let url = "";
    if(!isGlobalWallet) {
      url = '/wallet/'+walletNameSlug+'/stocks';
    } else {
      url = '/wallet-global/stocks';
    }
    return this.httpClient.post(environment.apiUrl+'user/'+userId+url, {tableData: tableData});
  }

  getStocksGlobal(userId: number|string, tableData: any): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'user/'+userId+'/wallet-global/stocks', {tableData: tableData});
  }

  create(name: string, unique_id: string, currency: String): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'wallet/store', {name: name, unique_id: unique_id, currency: currency});
  }

  currencies(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'wallet/currencies');
  }

  exchanges(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'wallet/exchanges');
  }

  add(walletNameSlug: string, data: object): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'wallet/'+walletNameSlug+'/add', {data: data});
  }

  delete(walletNameSlug: string): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'wallet/'+walletNameSlug+'/delete', {});
  }

  getTransactions(userId: number|string, walletNameSlug: number|string, walletStockId: string, isGlobalWallet: boolean = false): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'user/'+userId+'/wallet/'+walletNameSlug+'/transactions/'+walletStockId+'/'+isGlobalWallet, {});
  }

  uploadExcel(userId: number|string,walletNameSlug: number|string, file_excel:FormData): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'user/'+userId+'/wallet/'+walletNameSlug+'/upload-excel', file_excel);
  }
}
