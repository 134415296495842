import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from 'src/app/services/wallet.service';
import { ToastService } from 'src/app/services/toast.service';
import { Wallet } from 'src/app/models/wallet.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/jwt.service';
import { EventBusService } from '../../../shared/event-bus.service';
import { EventData } from '../../../shared/event.class';

@Component({
  selector: 'wallet-upload-dialog',
  templateUrl: './wallet-upload-dialog.component.html',
  styleUrls: ['./wallet-upload-dialog.component.scss']
})
export class WalletUploadDialogComponent implements OnInit {
  createForm = new FormGroup({
    name: new FormControl('', Validators.required),
    currency: new FormControl('', Validators.required)
  });
  userId = "";
  currencies: any = [];
  enableUploadButton:boolean = false;
  isUploading:boolean = false; 
  fileToUpload:any;
  wallet: Wallet;

  constructor(
    private dialogRef: MatDialogRef<WalletUploadDialogComponent>,
    private walletService: WalletService,
    private toastService: ToastService,
    private jwtServie: JwtService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.userId = this.jwtServie.getUser().id;
    this.wallet = data.wallet;
  };

  ngOnInit(): void {}

  onFileSelected(event:any): void {
    this.fileToUpload = event;
    this.enableUploadButton = !!event.name;
  }

  uploadFile(): void {
    this.isUploading = true;
    const formData = new FormData();
    formData.append("file_excel", this.fileToUpload);
    this.walletService.uploadExcel(this.userId, this.wallet.name_slug, formData).subscribe((data) => {
      this.toastService.show(data.message, {
        classname: (data.result ? 'bg-success' : 'bg-danger')+' text-light',
        delay: environment.toastDelay,
        autohide: true,
        result: data.result
      });
      this.dialogRef.close(data.result);
    })
  }

}
