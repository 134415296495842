<mat-sidenav-container class="example-container">
    <mat-sidenav class="sidebar-w-margin sidebar-primary-gradient" mode="side" opened>
        <sidebar></sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="site-content">
            <div>
                <div class="d-flex justify-content-center p-4">
                    <button mat-stroked-button class="area-btn mx-2" [ngClass]="{'area-selected': selectedArea == 'trend'}" color="primary" (click)="selectArea('trend')">Trend <i class="fas fa-chart-line"></i></button>
                    <button mat-stroked-button class="area-btn mx-2" [ngClass]="{'area-selected': selectedArea == 'news'}" color="primary" (click)="selectArea('news')">News <i class="far fa-newspaper"></i></button>
                    <button mat-stroked-button class="area-btn mx-2" [ngClass]="{'area-selected': selectedArea == 'hot'}" color="primary" (click)="selectArea('hot')">Hot <i class="fab fa-hotjar"></i></button>
                    <button mat-stroked-button class="area-btn mx-2" [ngClass]="{'area-selected': selectedArea == 'mylist'}" color="primary" (click)="selectArea('mylist')">My List <i class="fas fa-clipboard-list"></i></button>
                </div>
                <div class="d-flex flex-wrap justify-content-evenly area" *ngIf="selectedArea == 'trend'">

                </div>
                <div class="d-flex flex-wrap justify-content-evenly area" *ngIf="selectedArea == 'news'">
                    <news></news>
                </div>
                <div class="d-flex flex-wrap justify-content-evenly area" *ngIf="selectedArea == 'hot'">
                    <rankings class="rankings m-2"></rankings>
                    <macro-economic-calendar class="m-2"></macro-economic-calendar>
                </div>
                <div class="d-flex flex-wrap justify-content-evenly area" *ngIf="selectedArea == 'mylist'">

                </div>
            </div>
            <mat-tab-group mat-align-tabs="center">
                <mat-tab label="Euronext Milano">
                    <ng-template matTabContent>
                        <div class="d-flex flex-wrap justify-content-center">
                            <div class="best-and-worst-wrapper">
                                <div class="best-and-worst">
                                    <div class="text-center text-primary p-1">
                                        <span>Migliori</span>
                                    </div>
                                    <best-and-worst [index]="'IFMB'" [mode]="'best'"></best-and-worst>
                                </div>
                                <div class="best-and-worst">
                                    <div class="text-center text-primary p-1">
                                        <span>Peggiori</span>
                                    </div>
                                    <best-and-worst [index]="'IFMB'" [mode]="'worst'"></best-and-worst>
                                </div>
                            </div>
                            <div id="chart" class="">
                                <line-chart [stockKey]="'TIT.FTSEMIB.IDX'" class="d-block"></line-chart>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Euronext parigi">
                    <ng-template matTabContent>

                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <div class="d-flex flex-wrap justify-content-center">
                <div class="main-indexes">
                    <div class="text-center text-primary p-1">
                        <span>Indici Principali</span>
                    </div>
                    <main-indexes></main-indexes>
                </div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>