import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {  
  selectedArea:any = undefined;
  constructor() {}

  ngOnInit(): void {
  }

  selectArea(area:string) {
    if(area == this.selectedArea) {
      this.selectedArea = undefined;
    } else {
      this.selectedArea = area;
    }
  }

}
