import { Component, Input, OnInit } from '@angular/core';
import { StockService } from '../services/stock.service';

@Component({
  selector: 'best-and-worst',
  templateUrl: './best-and-worst.component.html',
  styleUrls: ['./best-and-worst.component.scss']
})
export class BestAndWorstComponent implements OnInit {
  @Input() mode: string = "";
  @Input() index: string = "";
  isLoading = false;
  dataSource: any = [];
  /*worst = [
    {'exchange': 'MCI', 'description': 'A2A', 'percChange': -3},
    {'exchange': 'MCI', 'description': 'ENI', 'percChange': -2.75},
    {'exchange': 'MCI', 'description': 'ENEL', 'percChange': -2.44},
    {'exchange': 'MCI', 'description': 'Autostrade', 'percChange': -2.12},
    {'exchange': 'MCI', 'description': 'FCA', 'percChange': -1.1},
  ];
  best = [
    {'exchange': 'MCI', 'description': 'A2A', 'percChange': +3},
    {'exchange': 'MCI', 'description': 'ENI', 'percChange': +2.75},
    {'exchange': 'MCI', 'description': 'ENEL', 'percChange': +2.44},
    {'exchange': 'MCI', 'description': 'Autostrade', 'percChange': +2.12},
    {'exchange': 'MCI', 'description': 'FCA', 'percChange': +1.1},
  ];*/
  displayedColumns = ['description','percChange'];
  constructor(
    private stockService: StockService
  ) { }

  ngOnInit(): void {    
    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    this.stockService.getBestWorst(this.index, this.mode).subscribe( (data) => {
      this.dataSource = data.data;
      this.isLoading = false;
    });
  }

}
