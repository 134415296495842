import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SidebarComponent } from './sidebar.component';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SidebarComponent,
  ],
  imports: [
    MatCardModule,
    MatButtonModule,
    RouterModule,
    CommonModule
  ],
  exports: [
    SidebarComponent,
  ]
})
export class SidebarModule { }