<h2 mat-dialog-title>Nuovo portafoglio</h2>
<mat-dialog-content>
    <form id="createWalletForm" [formGroup]="createForm" (ngSubmit)="onSubmit()" class="mt-3">
        <mat-form-field appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" placeholder="Inserisci un nome">
            <mat-error *ngIf="createForm.controls['name'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Id unico</mat-label>
            <input matInput formControlName="unique_id" placeholder="Inserisci un id">
            <mat-error *ngIf="createForm.controls['unique_id'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Valuta</mat-label>
            <mat-select formControlName="currency">
                <mat-option>
                    -- Seleziona la valuta --
                </mat-option>
                <mat-option *ngFor="let currency of currencies" [value]="currency.value">
                    {{currency.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="createForm.controls['currency'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary">Crea portafoglio</button>
        </div>
    </form>
</mat-dialog-content>