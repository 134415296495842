<ngb-toast *ngFor="let toast of toastService.toasts" [header]="toast.headertext" [class]="toast.classname" [autohide]="toast.autohide" [delay]="toast.delay || 5000" (hide)="toastService.remove(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        <div class="d-flex align-items-center">
            <i [ngClass]="toast.result ? 'fa-check-circle' : 'fa-exclamation-triangle'" class="fas toast-icon me-3"></i>
            <span [innerHtml]="toast.textOrTpl | safe:'html'"> </span>
        </div>
    </ng-template>
</ngb-toast>