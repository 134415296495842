import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { JwtService } from '../services/jwt.service';
import { EventBusService } from '../shared/event-bus.service';
import { EventData } from '../shared/event.class';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {
    email: null,
    password: null
  };

  signupForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    user_type: new FormControl('', Validators.required),
    institution_name: new FormControl(''),
  });

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  showSignupDone = false;

  constructor(
    private authService: AuthService, 
    private jwtService: JwtService,
    private router: Router,
    private eventBusService: EventBusService
  ) { }

  ngOnInit(): void {
    if (this.jwtService.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.jwtService.getUser().roles;
    }
  }

  onUserTypeChange(event: MatSelectChange): void {
    switch(event.value) {
      case "fund":
      case "institutional":
        this.signupForm.controls['institution_name'].setValidators([Validators.required]);
      break;
      default:
        this.signupForm.controls['institution_name'].setValidators([]);
      break;
    }
  }

  onSubmit(): void {
    const { email, password } = this.form;
    this.authService.login(email, password).subscribe({
      next: data => {        
        this.jwtService.saveToken(data.access_token);
        //this.jwtService.saveRefreshToken(data.refreshToken);
        this.jwtService.saveUser(data.user);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.jwtService.getUser().roles;
        this.eventBusService.emit(new EventData('login', null));
        this.router.navigate(['user', data.user.id, 'wallets']);
      },
      error: err => {
        this.errorMessage = err.message;
        this.isLoginFailed = true;
      }
    });
  }
  

  onSignup(): void {    
    if (this.signupForm.valid) {
      this.authService.signup(this.signupForm.value).subscribe( (data) => {
        this.showSignupDone = data.result;
      });
    }
  }
}
