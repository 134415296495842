import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private httpClient: HttpClient) { }

  getAll(exchange: string, name: string): Observable<any> {
    return this.httpClient.post(environment.apiUrl+'stocks/get-all', {name: name, exchange:exchange});
  }

  getBestWorst(index: string, type: string): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'stocks/'+type+'/'+index);
  }

  getLineOnCloseData(stockKey: string,chartView: string): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'linechart/'+stockKey+'/'+chartView);
  }

  getMainIndexes(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'main-indexes');
  }
}
