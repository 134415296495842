import { Component, OnInit } from '@angular/core';
import { RankingsService } from '../services/rankings.service';

@Component({
  selector: 'rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.scss']
})
export class RankingsComponent implements OnInit {
  dataSourceInv: any = [];
  dataSourceEsg: any = [];
  tableInvLoading = false;
  tableEsgLoading = false;
  invColumns = ['description','dpv_value','dpv_perc','sent_value','sent_perc','last','perc_change','date'];
  esgColumns = ['description','global','internal','external','last','perc_change','date'];

  constructor(
    private rankingsService: RankingsService
  ) { }

  ngOnInit(): void {
    this.getInvIndicators();
    this.getEsgIndicators();
  }

  getInvIndicators() :void {
    this.tableInvLoading = true;
    this.rankingsService.getInvIndicators().subscribe( (data) => {      
      this.tableInvLoading = false;
      if(data.result) {
        this.dataSourceInv = data.data;
      }
    });
  }

  getEsgIndicators() :void {
    this.tableEsgLoading = true;
    this.rankingsService.getEsgIndicators().subscribe( (data) => {      
      this.tableEsgLoading = false;
      if(data.result) {
        this.dataSourceEsg = data.data;
        console.log(data.data);
        
      }
    });
  }

}
