import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../../services/jwt.service';
import { WalletService } from 'src/app/services/wallet.service';
import { Wallet } from 'src/app/models/wallet.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WalletCreateDialogComponent } from '../dialogs/create/wallet-create-dialog.component';
import { EventBusService } from '../../shared/event-bus.service';
import { MatTabNav } from '@angular/material/tabs';

@Component({
  selector: 'wallet',
  templateUrl: './wallet-index.component.html',
  styleUrls: ['./wallet-index.component.scss']
})

export class WalletIndexComponent implements OnInit {
  wallets: Wallet[] = [];
  selectedWallet: Wallet | undefined;
  userId = "";
  @ViewChild(MatTabNav) private matTabNav!: MatTabNav;

  constructor(
    private walletService: WalletService,
    private router: Router,
    private route: ActivatedRoute,
    private jwtService: JwtService,
    private dialog: MatDialog,
    private eventBusService: EventBusService
  ) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id')!;
    this.loadWallets();
    this.eventBusService.on('reloadWallets', () => {
      this.loadWallets();
    });
  }

  ngAfterViewInit() {
this.matTabNav.ngAfterContentInit();
  }

  loadWallets(): void {
    this.walletService.getAll(this.userId).subscribe((data) => {
      this.wallets = data.wallets;
      this.wallets.push(new Wallet(0, 'global-view', 'Portafoglio globale',undefined,undefined,undefined,'EUR',undefined, true));
      if (this.route.children.length) {        
        let walletFound = false;
        const _selected = this.route.children[0].snapshot.paramMap.get('walletName')!;
        this.wallets.forEach(wallet => {
          if (wallet.name_slug == _selected) {
            walletFound = true;
            this.selectedWallet = wallet;
          }
        });        
        if(!walletFound && this.wallets.length > 0) {
          this.selectedWallet = this.wallets[this.wallets.length-1];
          this.selectWallet({ value: this.wallets[this.wallets.length-1] });
        }
      } else if(this.wallets.length > 0) {
        this.selectWallet({ value: this.wallets[0] });
      } 
    })
  }

  compareWalletName(p1: Wallet, p2: Wallet): boolean {
    if (p1 && p2) {      
      return p1.name_slug.toLowerCase() === p2.name_slug.toLowerCase();
    }
    return false;
  }

  selectWallet(event: any): void {
    let userId = this.jwtService.getUser().id;
    const val = event.value;            
    if (val) {
      if(val.id == 0) {
        //ptf globale
        this.router.navigate(['user', userId, 'wallets', val.name_slug]);
        this.selectedWallet = new Wallet(val.id, val.name_slug, val.name, val.unique_id, val.created_at, val.updated_at, val.currency);        
      } else {
        this.selectedWallet = new Wallet(val.id, val.name_slug, val.name, val.unique_id, val.created_at, val.updated_at, val.currency);        
        this.router.navigate(['user', userId, 'wallets', this.selectedWallet.name_slug]);
      }
    } else {
      this.router.navigate(['user', userId, 'wallets']);
    }
  }

  openCreateWallet(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    /*dialogConfig.data = {
      stock: element.stock,
      wallet: this.wallet,
      hideType: false
    };*/
    let dialogRef = this.dialog.open(WalletCreateDialogComponent, dialogConfig);
  }

}