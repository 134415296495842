<h2 mat-dialog-title>{{stock.Descrizione}}</h2>
<mat-dialog-content>
    <form id="addForm" [formGroup]="form" (ngSubmit)="onSubmit()" class="d-flex align-items-end flex-wrap">
        <ng-container *ngIf="!forceType">
            <mat-form-field class="m-2" appearance="outline">
                <mat-label>Acquisto / Vendita</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="'buy'">
                        Acquisto
                    </mat-option>
                    <mat-option [value]="'sell'">
                        Vendita
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['type'].hasError('required')">Campo obbligatorio</mat-error>
            </mat-form-field>
        </ng-container>
        <mat-form-field class="m-2" appearance="outline">
            <mat-label>Quantità</mat-label>
            <input matInput formControlName="quantity" placeholder="Inserisci la quantità">
            <mat-error *ngIf="form.controls['quantity'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field class="m-2" appearance="outline">
            <mat-label>Prezzo</mat-label>
            <input matInput formControlName="price" placeholder="Inserisci il prezzo">
            <mat-error *ngIf="form.controls['price'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <!--mat-form-field class="m-2" appearance="outline">
            <mat-label>Valuta</mat-label>
            <mat-select formControlName="currency">
                <mat-option>
                    -- Seleziona la valuta --
                </mat-option>
                <mat-option *ngFor="let currency of currencies" [value]="currency.value">
                    {{currency.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['currency'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field-->
        <div class="mat-form-field m-2">
            <mat-label id="fee-type-label">Tipo Commissioni</mat-label>
            <mat-radio-group aria-label="Seleziona il tipo di commissioni" formControlName="feeType" class="fee-radio-group" aria-labelledby="fee-type-label">
                <mat-radio-button color="primary" class="mx-2 my-1" value="fixed">Fisse</mat-radio-button>
                <mat-radio-button color="primary" class="mx-2 my-1" value="perc">Percentuali</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field class="m-2" appearance="outline">
            <mat-label>Commissioni</mat-label>
            <input matInput formControlName="fee" placeholder="Commissioni">
            <mat-error *ngIf="form.controls['fee'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <!--mat-form-field class="m-2" appearance="outline">
            <mat-label>Commissioni fisse</mat-label>
            <input matInput formControlName="fixedFee" placeholder="Inserisci le commissioni">
            <mat-error *ngIf="form.controls['fixedFee'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field class="m-2" appearance="outline">
            <mat-label>Commissioni %</mat-label>
            <input matInput formControlName="percFee" placeholder="Inserisci le commissioni">
            <mat-error *ngIf="form.controls['percFee'].hasError('required')">Campo obbligatorio</mat-error>
        </mat-form-field-->
        <div class="w-100 mt-3 mb-1">
            <button mat-raised-button color="primary" class="mx-2">Conferma</button>
            <button mat-raised-button class="mx-2" type="button" (click)="close(false)">Annulla</button>
        </div>
    </form>
</mat-dialog-content>