import { Component, OnInit } from '@angular/core';
import { StockService } from '../services/stock.service';

@Component({
  selector: 'main-indexes',
  templateUrl: './main-indexes.component.html',
  styleUrls: ['./main-indexes.component.scss']
})
export class MainIndexesComponent implements OnInit {
  indexTableLoading = false;
  dataSource: any = [];
  displayedColumns = ['description','date','last','percChange','icon'];

  constructor(
    private stockService: StockService
  ) { }

  ngOnInit(): void {
    this.getMainIndexes();
  }

  getMainIndexes(): void {
    this.stockService.getMainIndexes().subscribe( (data) => {
      if(data.result) {        
        this.dataSource = data.data;
      }
    });
  }
}
