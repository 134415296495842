import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private httpClient: HttpClient) { }

  getNews(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'news');
  }

  getMacroEconomicCalendarEvents(): Observable<any> {
    return this.httpClient.get(environment.apiUrl+'macro-economic-calendar-events');
  }
}
