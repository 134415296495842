import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Wallet } from 'src/app/models/wallet.model';
import { StockService } from 'src/app/services/stock.service';
import { ToastService } from 'src/app/services/toast.service';
import { WalletService } from 'src/app/services/wallet.service';
import { TransactionComponent } from '../dialogs/transaction/transaction.component';

@Component({
  selector: 'app-wallet-add',
  templateUrl: './wallet-add.component.html',
  styleUrls: ['./wallet-add.component.scss']
})
export class WalletAddComponent implements OnInit {
  wallet: Wallet | undefined;
  userId = "";
  exchanges: any = [];
  stocks: any = [];
  dataSource: any[] = [];
  displayedColumns: string[] = ['Descrizione', 'Isin', 'Simbolo', 'tools'];
  isLoadingResults = false;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    exchange: new FormControl('', Validators.required)
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private walletService: WalletService,
    private stockService: StockService,
    private toastService: ToastService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userId = this.route.parent!.snapshot.paramMap.get('id')!;
    this.walletService.get(this.userId, this.route.snapshot.paramMap.get('walletName')!).subscribe((data)=>{
      this.wallet = data.wallet;      
    })
    this.walletService.exchanges().subscribe((data)=>{
      this.exchanges = data.exchanges;            
    });
  }

  onSubmit(): void {
    if(this.form.valid) {
      this.isLoadingResults = true;
      this.stockService.getAll(this.form.controls['exchange'].value, this.form.controls['name'].value).subscribe( (data) => {        
        this.stocks = data.stocks;
        console.log(this.stocks);
        this.isLoadingResults = false;
      });
    }
  }

  onAdd(element:any): void {
    this.openDialog(element);
  }

  openDialog(stock:any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    
    dialogConfig.data = {
      stock: stock,
      wallet : this.wallet,
      forceType: 'buy'
    };
    this.dialog.open(TransactionComponent, dialogConfig);
  }

}
