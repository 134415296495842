<div class="row mt-3 justify-content-center">
    <div class="col-12 col-sm-8 col-lg-4 text-center">
        <mat-card>
            <mat-card-title class="text-center">Nuovo portafoglio</mat-card-title>
            <mat-card-content id="create-wallets">
                <form id="createWalletForm" [formGroup]="createForm" (ngSubmit)="onSubmit()" class="mt-3">
                    <mat-form-field class="mb-3" appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="name" placeholder="Inserisci un nome">
                        <mat-error *ngIf="createForm.controls['name'].hasError('required')">Campo obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field class="mb-3" appearance="outline">
                        <mat-label>Id</mat-label>
                        <input matInput formControlName="unique_id" placeholder="Inserisci un id">
                        <mat-error *ngIf="createForm.controls['unique_id'].hasError('required')">Campo obbligatorio</mat-error>
                    </mat-form-field>
                    <mat-form-field class="mb-3" appearance="outline">
                        <mat-label>Valuta</mat-label>
                        <mat-select formControlName="currency">
                            <mat-option>
                                -- Seleziona la valuta --
                            </mat-option>
                            <mat-option *ngFor="let currency of currencies" [value]="currency.value">
                                {{currency.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="createForm.controls['currency'].hasError('required')">Campo obbligatorio</mat-error>
                    </mat-form-field>
                    <div>
                        <button mat-raised-button color="primary" class="w-100">Crea portafoglio</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>