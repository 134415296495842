import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { EventBusService } from '../shared/event-bus.service';
import { EventData } from '../shared/event.class';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private jwtService: JwtService,
    private eventBusService: EventBusService
  ) { }
  
  login(email: string, password: string): Observable<any> {
    return this.httpClient.post(environment.apiUrl + 'auth/login', {
      email,
      password
    }, httpOptions);
  }

  logout(): void {
    this.jwtService.logout();
    this.eventBusService.emit(new EventData('logout', null));
  }

  refreshToken() {
    return this.httpClient.post(environment.apiUrl + 'auth/refresh', {}, httpOptions);
  }

  signup(data: any): Observable<any> {
    return this.httpClient.post(environment.apiUrl + 'auth/signup', {
      data
    }, httpOptions);
  }
}
