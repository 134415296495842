import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { JwtService } from '../services/jwt.service';
declare var jQuery: any;

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isLoggedIn: boolean = false;
  user: any = {};

  constructor(
    private jwtService: JwtService,
    
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.jwtService.isLoggedIn();
    if(this.isLoggedIn) {
      this.user = this.jwtService.getUser();
    }
  }

  /*ngAfterViewInit(){
    this.onResizeSidebar();
    this.resizeSidebar();
  }

  resizeSidebar(): void {
    jQuery('#sidebar-left').css("height", jQuery('body').height() - jQuery('.navbar').first().height() - 50);
  }

  onResizeSidebar(): void {
    var $window = jQuery(window);
    //jQuery(window).on('resize', this.resizeSidebar);
  }*/

}
